import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import background from "./astral.png"
import astral from "./astral2.png"
import circus from "./circusdaddy.png"
import tlg from "./tlg.png"
import carto from "./carto.png"
import waifu from "./waifu.png"
import chromatic from "./chromatic.png"
import demonic from "./demonic.png"
import tpl from "./3PL.png"
import brassart from "./brassart.png"
import pdn from "./PDN.png"
import npc from "./npcai.png"
import cityverse from "./cityverse.png"
import YouTube from 'react-youtube';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" >
        Guillaume Cailhe
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    minHeight: '100vh'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: "#f0f0f0",
    padding: theme.spacing(6),
  },
  loaderDiv: {
    backgroundColor: "black",
    height: "100%",
    width: "100%",
    position: "fixed",
  },
  loader:{
    position: "absolute",
    top: "48%",
    left: "48%"
  },
  h5:{
    marginTop: "50px",
  }
}));

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Guillaume Cailhe
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Indie Game Developper - Sheraf Games
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}

            {/* Projects */}
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Projects
            </Typography>
            <Grid container spacing={4}>

             <Grid item key={1} xs={12}>
                <Card style={{paddingTop: "5vh"}} >
                  <YouTube videoId="cu5i2pHWass" />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Paint Over
                    </Typography>
                    <Typography>
                      Embark on a surreal journey through a world of living paintings in this first-person puzzle game.<br/>
                      Draw symbols on canvases to uncover secrets and solve challenging puzzles. <br/>
                      Use your wit and creativity to progress! <br/>
                      <a href="https://store.steampowered.com/app/2339590/Paint_Over/">Wishlist and try the demo on Steam!</a>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

               <Grid item key={1} xs={12}>
                <Card style={{paddingTop: "5vh"}} >
                  <YouTube videoId="9vLhIyjc77Y" />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Phobia Exposure VR
                    </Typography>
                    <Typography>
                      Phobia Exposure VR offers immersive exposure therapy in virtual reality. <br/>
                      Conquer your fears with gradual levels of exposure, guided relaxation, and personal progress tracking. <br/>
                      Overcome phobias at your own pace! <br/>
                      <a href="https://store.steampowered.com/app/2509570/Phobia_Exposure_VR/">Try for free on Steam!</a>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item key={1} xs={12}>
                <Card className={"card"} >
                  <a href="https://twitter.com/Helgrind444/status/1349685117212626946" target="_blank" rel="noreferrer">
                    <CardMedia
                      className={classes.cardMedia}
                      image={demonic}
                      title="Demonic Academy"
                    />
                  </a>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Demonic Academy
                    </Typography>
                    <Typography>
                      Cooperative first person puzzle game made with Unreal Engine 5 (a first POC was made with Godot).<br/>
                      Players explore an academy of demonology and learn the physics of the demonic plan by traveling between two parallel worlds. <br/>
                      Every object is the same place in both worlds but with some differences... <br/>
                      That solid ice ? It's molten lava in the demonic plan! <br/>
                      Cooperation is the key to solve these puzzles. <br/>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>


              <Grid item key={1} xs={12}>
                <Card className={"card"} >
                  <a href="https://twitter.com/PatteDeCanaard/status/1377286369744146438" target="_blank" rel="noreferrer">
                    <CardMedia
                      className={classes.cardMedia}
                      image={astral}
                      title="Astral"
                    />
                  </a>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Astral
                    </Typography>
                    <Typography>
                      3D Puzzle/Exploration game made with Godot, in a team of 3. <br/>
                      Use your power to control clones and solve puzzles in this cute game inspired by The Wind Waker. <br/>
                      I made most of the art (models and handpainted textures), level design and programmed some tools.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid> 

            {/* Freelance */}
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
                Freelance Work
            </Typography>
            <Grid container spacing={4}>

              <Grid item key={1} xs={6}>
                <Card className={"card"} >
                    <CardMedia
                      className={classes.cardMedia}
                      image={brassart}
                      title="Brassart"
                    />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Teaching experience at Brassart
                    </Typography>
                    <Typography>
                      I've taught Unreal and Unity engines to two Game Design classes for two years.<br/>
                      Together, we've learned the basics of both engines and I helped the students with their projects. <br/>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            

            <Grid item key={1} xs={6}>
                <Card className={"card"} >
                    <CardMedia
                      className={classes.cardMedia}
                      image={pdn}
                      title="UNITAR Metaverse"
                    />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      UNITAR Metaverse
                    </Typography>
                    <Typography>
                      For Berexia, I've worked as a lead dev on creating a metaverse for the UNITAR, a branch of the UN.<br/>
                      The experience uses Pixel Streaming.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

            <Grid item key={1} xs={6}>
                <Card className={"card"} >
                    <CardMedia
                      className={classes.cardMedia}
                      image={npc}
                      title="NPC AI"
                    />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      NPC LLM (Unity)
                    </Typography>
                    <Typography>
                      For Berexia, I've worked on implementing a NPC that uses LLM APIs to help the user of a metaverse.<br/>
                    </Typography>
                  </CardContent>
                </Card>
            </Grid>

            <Grid item key={1} xs={6}>
                <Card className={"card"} >
                    <CardMedia
                      className={classes.cardMedia}
                      image={cityverse}
                      title="Cityverse"
                    />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Cityverse (Unity)
                    </Typography>
                    <Typography>
                      For Berexia, I've worked on creating a map using real geographic data.<br/>
                      This VR metaverse is used to promote sustainable development goals by letting users create events.<br/>
                      Users can then visit places on the map in VR. 
                    </Typography>
                  </CardContent>
                </Card>
            </Grid> 
          </Grid>  

            {/* Plugins */}
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
                Plugins
            </Typography>
            <Grid container spacing={4}>

              <Grid item key={1} xs={12}>
                <Card className={"card"} >
                  <YouTube videoId="PKu55ndKqPM" />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Three Point Lighting (Unreal Marketplace)
                    </Typography>
                    <Typography>
                        Three-Point Lighting simplifies the process of setting up professional lighting in your scenes.<br/>
                        Easily configure key, fill, and back lights with preset options to streamline your workflow within the editor.<br/>
                        <a href="https://www.unrealengine.com/marketplace/en-US/product/three-point-lighting">Buy on the Unreal Marketplace!</a>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>               
            </Grid>  

            {/* Gamejams */}
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
                Gamejams
            </Typography>
            <Grid container spacing={4}>

              <Grid item key={1} xs={6}>
                <Card className={"card"} >
                  <a href="https://pattedecanaard.itch.io/circus-daddy" target="_blank" rel="noreferrer">
                    <CardMedia
                      className={classes.cardMedia}
                      image={circus}
                      title="CircusDaddy"
                    />
                  </a>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Circus Daddy
                    </Typography>
                    <Typography>
                      3D platformer made with Godot for the Ludum Dare #46. <br/>
                      You're an acrobat in a circus with a twist... You brought you baby daughter to work! <br/>
                      Every time you jump she escapes from your arms, so you have to time your jumps correctly to catch her before she falls.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item key={1} xs={6}>
                <Card className={"card"} >
                  <a href="https://7pri2.itch.io/the-cartographer" target="_blank" rel="noreferrer">
                    <CardMedia
                      className={classes.cardMedia}
                      image={carto}
                      title="Cartographer"
                    />
                  </a>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      The Cartographer
                    </Typography>
                    <Typography>
                      2D puzzle game made for the Godot Wild Jam #19. <br/>
                      In this game, you have to explore a dungeon and map it for adventurers. <br/>
                      Voted second best game at the Godot Wild Jam #19
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item key={1} xs={4}>
                <Card className={"card"} >
                  <a href="https://pattedecanaard.itch.io/the-last-garden" target="_blank" rel="noreferrer">
                    <CardMedia
                      className={classes.cardMedia}
                      image={tlg}
                      title="The Last Garden"
                    />
                  </a>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      The Last Garden
                    </Typography>
                    <Typography>
                      2D strategic game made for the Pot-Au-Jeu 2.0  <br/>
                      Place your plants to defend yourself against robotic insects!
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item key={1} xs={4}>
                <Card className={"card"} >
                  <a href="https://helgrind.itch.io/my-waifu-is-a-rock" target="_blank" rel="noreferrer">
                    <CardMedia
                      className={classes.cardMedia}
                      image={waifu}
                      title="My waifu is a rock"
                    />
                  </a>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      My waifu is a rock
                    </Typography>
                    <Typography>
                      2D platformer made with Godot for the GMTK 2019.<br/>
                      You are in love with a beautiful rock and throw it on many things to progress. <br/>
                      But because of your weird feelings for this inamite object, you cannot lose your only projectile and need to protect it at all cost!
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item key={1} xs={4}>
                <Card className={"card"} >
                  <a href="https://pattedecanaard.itch.io/chromatic-fever" target="_blank" rel="noreferrer">
                    <CardMedia
                      className={classes.cardMedia}
                      image={chromatic}
                      title="Chromatic Fever"
                    />
                  </a>
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Chromatic Fever
                    </Typography>
                    <Typography>
                      2D shooter made with Godot for the Potat0s Jam.<br/>
                      We mostly experiment on game feel with this one to deliver a fast paced shooter. <br/>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>  

        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
